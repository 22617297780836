<template>
  <div class="page_container">
    <div class="page_head">
      <div class="page_head-title">SETTINGS</div>
    </div>
    <div class="page_content">
      <div class="create_content">
        <form @submit.prevent="updateUser()">

          <div class="create_row">

            <div class="input_item">
              <label>Name</label>
              <b-form-input v-model="user_data.name"
                            ref="name_input"
                            class="create_input"
                            :class="{ 'is-invalid': isSubmitted && $v.user_data.name.$error }"
              >
              </b-form-input>
              <div v-if="isSubmitted && $v.user_data.name.$error" class="invalid-feedback">
                <span v-if="!$v.user_data.name.required">Name field is required</span>
              </div>
            </div>
            <div class="input_item">
              <label>Surname</label>
              <b-form-input v-model="user_data.surname"
                            ref="surname_input"
                            class="create_input"
                            :class="{ 'is-invalid': isSubmitted && $v.user_data.surname.$error }"
              >
              </b-form-input>
              <div v-if="isSubmitted && $v.user_data.surname.$error" class="invalid-feedback">
                <span v-if="!$v.user_data.surname.required">Surname field is required</span>
              </div>
            </div>
          </div>

          <div  class="create_row">
            <div class="input_item">
              <label>Email</label>
              <b-form-input v-model="user_data.email"
                            class="create_input"
                            type="email"
                            :class="{ 'is-invalid': isSubmitted && $v.user_data.email.$error }"
                            disabled
              >
              </b-form-input>
              <div v-if="isSubmitted && $v.user_data.email.$error" class="invalid-feedback">
                <span v-if="!$v.user_data.email.required">Email field is required</span>
                <span v-if="!$v.user_data.email.email">Please provide valid email</span>
              </div>
            </div>

            <div class="input_item">
              <label>Old Password</label>
              <b-form-input v-model="user_data.old_password"
                            ref="old_password_input"
                            class="create_input" :type="o_pass"
                            :class="{ 'is-invalid': isSubmitted && $v.user_data.old_password.$error }"
              >
              </b-form-input>
              <div :class="{'eye_close': o_pass == 'password', 'eye_open': o_pass == 'text',}"
                   @click="showPassword('o_pass')"></div>
              <div v-if="isSubmitted && $v.user_data.old_password.$error" class="invalid-feedback">
                <span v-if="!$v.user_data.old_password.minLength">Password should be at least 6 characters long</span>
              </div>
              <span v-if="pass_error.old_password" class="text_danger">{{pass_error.old_password[0]}}</span>
              <span v-if="pass_error.old_pass_match" class="text_danger">{{pass_error.old_pass_match[0]}}</span>
            </div>
          </div>

          <div class="create_row">
            <div class="input_item">
              <label> New Password</label>
              <b-form-input v-model="user_data.password"
                            ref="password_input"
                            class="create_input" :type="r_pass"
                            :class="{ 'is-invalid': isSubmitted && $v.user_data.password.$error }"
              >
              </b-form-input>
              <div :class="{'eye_close': r_pass == 'password', 'eye_open': r_pass == 'text',}"
                   @click="showPassword('r_pass')"></div>
              <div v-if="isSubmitted && $v.user_data.password.$error" class="invalid-feedback">
                <span v-if="!$v.user_data.password.minLength">Password should be at least 6 characters long</span>
              </div>
              <span v-if="pass_error.new_password" class="text_danger">{{pass_error.new_password[0]}}</span>
            </div>

            <div class="input_item">
              <label>Confirm New Password</label>
              <b-form-input v-model="user_data.c_password"
                            ref="c_password_input"
                            class="create_input" :type="r_confirm"
                            :class="{ 'is-invalid': isSubmitted && $v.user_data.c_password.$error }"
              >
              </b-form-input>
              <div :class="{'eye_close': r_confirm == 'password', 'eye_open': r_confirm == 'text',}"
                   @click="showPassword('r_confirm')"></div>
              <div v-if="isSubmitted && $v.user_data.c_password.$error" class="invalid-feedback">
                <span v-if="!$v.user_data.c_password.minLength">Confirm Password should be at least 6 characters long</span>
                <span v-if="!$v.userForm.c_password.sameAsPassword">Passwords must match</span>
              </div>
              <span v-if="pass_error.c_password" class="text_danger">{{pass_error.c_password[0]}}</span>
            </div>
          </div>

          <div class="create_row">

<!--            <div class="input_item">-->
<!--              <label>School Type</label>-->
<!--              <b-form-select-->
<!--                  v-model="user_data.school_type"-->
<!--                  :options="school_type"-->
<!--                  value-field="value"-->
<!--                  text-field="label"-->
<!--                  :class="{ 'is-invalid': isSubmitted && $v.user_data.school_type.$error }"-->
<!--              >-->
<!--                <template #first>-->
<!--                  <b-form-select-option value="null" disabled>Choose School</b-form-select-option>-->
<!--                </template>-->
<!--              </b-form-select>-->
<!--              <div v-if="isSubmitted && $v.user_data.school_type.$error" class="invalid-feedback">-->
<!--                <span v-if="!$v.user_data.school_type.required">School type field is required</span>-->
<!--              </div>-->
<!--            </div>-->

            <div class="input_item">
              <div class="d-flex align-items-center">
                <b-button class="update_btn" type="submit">Update User</b-button> <span v-if="success_msg" class="text_success ml-3">{{success_msg}}</span>
              </div>

            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {email, minLength, required, sameAs} from "vuelidate/lib/validators";

export default {
  name: "Settings",
  data() {
    return {
      isSubmitted: false,
      userError: null,
      o_pass: 'password',
      r_pass: 'password',
      r_confirm: 'password',
      user_data: {
        name: '',
        surname: '',
        email: '',
        old_password: '',
        password: '',
        c_password: '',
        roles: ''
      },
      success_msg: null,
      pass_error: [],
      // school_type: [
      //   {
      //     label: 'Middle School',
      //     value: 'middle'
      //   },
      //   {
      //     label: 'High School',
      //     value: 'high'
      //   },
      //   {
      //     label: 'Middle and High School',
      //     value: 'middle_high'
      //   }
      // ],
    }
  },
  validations: {
    user_data: {
      name: {
        required
      },
      surname: {
        required
      },
      // school_type: {
      //   required
      // },
      email: {
        required,
        email
      },
      old_password: {
        minLength: minLength(6)
      },
      password: {
        minLength: minLength(6)
      },
      c_password: {
        minLength: minLength(6),
        sameAsPassword: sameAs('password')
      },
    }
  },
  created() {

  },
  computed: {
    ...mapGetters(['logUserData'])
  },
  mounted() {
    setTimeout(() => {
      this.user_data.name = this.logUserData.name
      this.user_data.surname = this.logUserData.surname
      this.user_data.email = this.logUserData.email
      this.$refs.old_password_input.$el.value = ''
      this.$refs.password_input.$el.value = ''
      this.$refs.c_password_input.$el.value = ''
    }, 500)
  },
  methods: {
    ...mapActions(['updateMyUser', 'getUserDetails']),
    async updateUser() {
      this.isSubmitted = true;
      this.userError = null
      this.pass_error = []
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        await this.updateMyUser(this.user_data)
            .then((res) => {
              if (res.data) {
                this.success_msg = res.data.message
                setTimeout(() => {
                  this.success_msg = null
                }, 3000)
                this.getUserDetails()
                if(res.data.message == 'Password was changed') {
                  setTimeout(() => {
                    this.$store.commit('clearUserData')
                    this.$router.push('/')
                  }, 1500)
                }
              } else {
                this.pass_error = res.response.data.error
              }
            })
      }
    },
    showPassword(item) {
      if (item === 'r_pass') {
        if (this.r_pass === 'password') {
          this.r_pass = 'text'
        } else {
          this.r_pass = 'password'
        }
      } else if(item === 'o_pass'){
        if (this.o_pass === 'password') {
          this.o_pass = 'text'
        } else {
          this.o_pass = 'password'
        }
      } else {
        if (this.r_confirm === 'password') {
          this.r_confirm = 'text'
        } else {
          this.r_confirm = 'password'
        }
      }
    },
  }
}
</script>

<style scoped lang="scss">
.page {
  &_container {
    width: 100%;
  }
  &_head {
    width: 100%;
    height: 73px;
    background: linear-gradient(180deg, #003761 0%, #00528C 79.64%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    position: sticky;
    top: 0;
    z-index: 3;

    &-title {
      color: #FFFFFF;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  &_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 40px 60px;
    & .create {
      &_content {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom: 15px;
      }
      &_row {
        max-width: 1100px;
        width: 100%;
        display: flex;
        align-items: flex-end;
        & .input_item {
          padding: 5px 20px 5px 0;
          max-width: 490px;
          height: 130px;
          display: flex;
          flex-direction: column;
          position: relative;
          & label {
            color: #11334D;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            &.create {
              color: transparent;
              visibility: hidden;
            }
          }
          & input {
            border-radius: 8px;
            border: 1px solid #11334D;
            width: 470px;
            height: 44px !important;
            /*background: url("~@/assets/icons/arrow_down.svg")  no-repeat;*/
            /*background-position: calc(100% - 0.7rem) center !important;*/
            /*-moz-appearance:none !important;*/
            /*-webkit-appearance: none !important;*/
            /*appearance: none !important;*/
            /*padding-right: 2rem !important;*/
            color: #11334D;
            font-size: 18px;
            font-style: italic;
            font-weight: 500;
          }
          & select {
            border-radius: 8px;
            border: 1px solid #11334D;
            width: 470px;
            height: 44px !important;
            background: url("~@/assets/icons/arrow_down.svg")  no-repeat;
            background-position: calc(100% - 0.7rem) center !important;
            -moz-appearance:none !important;
            -webkit-appearance: none !important;
            appearance: none !important;
            padding-right: 2rem !important;
            color: #11334D;
            font-size: 18px;
            font-style: italic;
            font-weight: 500;
          }
          & .eye_open {
            position: absolute;
            width: 24px;
            height: 24px;
            right: 35px;
            top: 52px;
            background: url("~@/assets/icons/eye_open.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            cursor: pointer;
          }
          & .eye_close {
            position: absolute;
            width: 24px;
            height: 24px;
            right: 35px;
            top: 52px;
            background: url("~@/assets/icons/eye_close.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            cursor: pointer;
          }
          & .update_btn {
            height: 44px;
            border-radius: 12px;
            border: 1px solid #FFFFFF;
            background: #00A99D;
            color: #FFFFFF;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px 25px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>